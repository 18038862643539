<template>
  <li class="portfolio-user-row">
    <router-link :to="{ name: 'adminUser', params: { user_id: user.id } }">
      <strong>{{ user.first_name }} {{ user.last_name }}</strong>
      <span>{{ user.company_name }}</span>
      <span>{{ user.email }}</span>
      <span>{{ user.role }}</span>
    </router-link>
    <section class="button-section">
      <slot />
    </section>
  </li>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style>
.portfolio-user-row:first-child::before,
.portfolio-user-row::after {
  content: '';
  grid-column: 1 / -1;
  border-bottom: var(--box-border);
  justify-self: stretch;
}

.portfolio-user-row > a {
  display: contents;
}

#app > main .portfolio-user-row > a > h3 {
  margin-bottom: 0;
  font-weight: 600;
}

.portfolio-user-row .button-section {
  grid-column-end: -1;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  justify-content: start;
  align-items: baseline;
  gap: var(--spacing-m);
  align-self: center;
}

.portfolio-user-row .button-section a {
  opacity: 0.7;
}

.portfolio-user-row .button-section a:hover {
  opacity: 1;
}

.portfolio-user-row > button {
  justify-self: stretch;
}
</style>
